<template>
	<div class="explore" v-loading="loading" element-loading-background="rgb(19, 21, 48)">
		<search-bar />

		<!-- <el-select v-model="type">
			<el-option label="结果为空" value="resultEmpty"></el-option>
			<el-option label="初始" value="normal"></el-option>
			<el-option label="有结果" value="result"></el-option>
		</el-select> -->
		<div v-if="!exploreMaintain">
			<div v-show="type == 'normal'">
				<div class="title" v-show="!loading">
					<div class="title-1">Hey, {{ userData.userInfo.nickname }}！</div>
					<div class="title-2">今天想看点什么？等你来探！</div>
				</div>
				<div class="type-box">
					<div class="type-search-box">
						<el-input
							class="search-input"
							:placeholder="'搜索...'"
							v-model="searchVal"
							@keyup.enter.native="search(searchVal)"
						>
							<img
								:src="seachImage"
								alt=""
								slot="prefix"
								style="
									width: 20px;
									height: 20px;
									margin-top: 10px;
									margin-left: 12px;
								"
							/>
						</el-input>
					</div>
					<el-tabs v-model="activeName" @tab-click="clickTab" v-show="!loading">
						<el-tab-pane
							:label="item.channelName"
							:name="item.channelName"
							v-for="(item, index) in channelList"
							:key="index"
						>
							<div class="explore-list-wrapper">
								<div
									class="moive-list"
									style="min-height: 300px"
									v-loading="tabLoading"
									element-loading-background="rgb(19, 21, 48)"
								>
									<div class="moive-item" v-for="(item, index) in news" :key="index">
										<div class="moive-img">
											<!-- <img :src="getImg(item.videoInfo.poster, '166', '240')" alt="" /> -->
											<el-image lazy :src="item.videoInfo.poster" style="width:166px;height: 240px;display: block;"  :isWrapper="false">
											</el-image>
											<div class="moive-title-box">
												<div class="title-1">{{ item.videoInfo.name }}</div>
												<div class="title-2">
													{{ item.videoInfo.releaseTime }}
												</div>
											</div>
											<div class="moive-tag">
												{{ typeObject[item.videoInfo.videoType] }}
											</div>
										</div>
										<div class="moive-button" @click="addWatch(item)">
											<img :src="wantIcon" alt="" />
											<span>我想看</span>
										</div>
									</div>
								</div>
							</div>
						</el-tab-pane>
					</el-tabs>

					<div class="moive-title" v-show="!loading">
						<img :src="timeIcon" alt="" />
						<span>即将上映</span>
					</div>
					<div>
						<div class="moive-list-history" v-show="!loading">
							<div class="moive-item" v-for="(item, index) in ongoing" :key="index">
								<div class="moive-img">
									<el-image lazy :src="item.videoInfo.poster" style="width:166px;height: 240px;display: block;"  v-show="index < 7">
									</el-image>
									<el-image lazy :src="item.videoInfo.poster" style="width:166px;height: 240px;display: block;" v-show="index >=7"  :isWrapper="false">
									</el-image>
	<!--								<img :src="getImg(item.videoInfo.poster, '166', '240')" alt=""  v-show="index < 7"/>-->
	<!--								<lazy-pic :array="[item.videoInfo.poster, '166', '240']" v-show="index >=7"  :isWrapper="false" />-->
									<div class="moive-tag">
										{{ typeObject[item.videoInfo.videoType] }}
									</div>
								</div>
								<div class="moive-title-box">
									<div class="title-1">{{ item.videoInfo.name }}</div>
									<div class="title-2">{{ item.videoInfo.releaseTime }}</div>
								</div>
							</div>
						</div>
					</div>

					<div class="moive-title" v-show="!loading">
						<img :src="groupImage" alt="" />
						<span>组队中</span>
					</div>
					<div>
						<div class="moive-list-matching" v-show="!loading">
							<div class="moive-item" v-for="(item, index) in matching" :key="index">
								<div class="moive-img">
									<el-image lazy :src="item.videoInfo.poster" style="width:166px;height: 240px;display: block;" v-show="index < 4">
									</el-image>
									<el-image lazy :src="item.videoInfo.poster" style="width:166px;height: 240px;display: block;" v-show="index >=4"  :isWrapper="false" >
									</el-image>
	<!--								<img :src="getImg(item.videoInfo.poster, '166', '240')" alt=""  v-show="index < 4"/>-->
	<!--								<lazy-pic :array="[item.videoInfo.poster, '166', '240']" v-show="index >=4"  :isWrapper="false" />-->
									<div class="moive-tag">
										{{ typeObject[item.videoInfo.videoType] }}
									</div>
								</div>
								<div class="moive-title-box">
									<div class="title-1">{{ item.videoInfo.name }}</div>
									<div class="title-2">
										{{ item.launchDate ? item.launchDate.substring(0, 10) : item.launchDate }}
									</div>
									<div class="title-3" @click="addWatch(item)">
										<img :src="joinIcon" alt="" />
										<span>还差{{ item.remain }}人</span>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>

			<div v-show="type == 'resultEmpty'">
				<div class="result-title">
					<span class="result-title-keyword">{{ keyword }}</span>
					<span class="result-title-number">共探索出 0 条结果</span>
				</div>
				<div class="result-content">
					<div class="result-content-title1">
						暂未找到
						<span class="keyword">“{{ keyword }}”</span>
						相关的视频
					</div>
					<div class="result-contennt-title2">请尝试更换原名或别名搜索</div>
					<img :src="emptyIcon" alt="" />
				</div>
			</div>
			<div v-show="type == 'result'">
				<div class="result-title">
					<span class="result-title-keyword">{{ keyword }}</span>
					<span class="result-title-number">共探索出 {{ searchList.length }} 条结果</span>
				</div>
				<div class="result-box">
					<div v-for="(item, index) in searchList" :key="index" class="result-item">
						<div class="result-item-img">
							<img :src="item.videoInfo.poster" alt="" style="width:166px;height: 240px;display: block;"/>
	<!--						<el-image lazy :src="item.videoInfo.poster" style="width:166px;height: 240px;display: block;" >-->
	<!--						</el-image>-->
							<div class="moive-tag">
								{{ typeObject[item.videoInfo.videoType] }}
							</div>
						</div>
						<div class="result-item-text">
							<div class="result-item-text-box">
								<div class="result-item-text-box-title">
									{{ item.videoInfo.name }}
								</div>
								<div class="date">{{ item.videoInfo.releaseTime }}</div>
							</div>
							<div
								class="moive-button"
								style="margin-left: 20px"
								v-show="item.status == 'new'"
								@click="addWatch(item)"
							>
								<img :src="wantIcon" alt="" />
								<span>我想看</span>
							</div>
							<div
								v-show="item.status == 'ongoing'"
								style="
									margin-left: 20px;
									margin-top: 10px;
									font-size: 20px;
									font-family: PingFang SC;
									font-weight: 500;
									line-height: 28px;
									color: #ff3465;
								"
							>
								即将上映
							</div>

							<div
								v-show="item.status == 'deleted'"
								style="
									margin-left: 20px;
									margin-top: 10px;
									font-size: 20px;
									font-family: PingFang SC;
									font-weight: 500;
									line-height: 28px;
									color: rgba(121, 122, 142, 1);
								"
							>
								不符合条件
							</div>

							<div
								v-show="item.status == 'onshow'"
								style="
									margin-left: 20px;
									margin-top: 10px;
									font-size: 20px;
									font-family: PingFang SC;
									font-weight: 500;
									line-height: 28px;
									color: #797a8e;
								"
							>
								已上映
							</div>
						</div>
					</div>
				</div>
				<page-select v-show="searchList.length" :pagination="pagination" @pageChange="pageChange"></page-select>
			</div>
		</div>

		<div v-if="exploreMaintain">
			<div style="text-align: center;width: 100%;min-height: 250px">
				<img :src="warnImage" alt="" style="margin-top: 50px;width: 400px;height: 250px;margin: 110px auto auto auto">
				<div style="font-size: 15px;
font-family: PingFang SC;
font-weight: 500;
line-height: 21px;
color: #797A8E;">{{exploreMaintainMsg}}</div>
			</div>
		</div>
	</div>
</template>

<script>
import searchBar from "./search-bar"
import pageSelect from "./page-select"
export default {
	name: "Explore",
	data() {
		return {
			// type: "resultEmpty",
			// type: "result",
			type: "normal",
			warnImage: require("@assets/img_maintain.png"),

			backgroundImage: require("@assets/index.png"),
			avatarImage: require("@assets/avatar.png"),
			wantIcon: require("@assets/explore/want.png"),
			timeIcon: require("@assets/aside/aside7.png"),
			emptyIcon: require("@assets/explore/empty.png"),
			joinIcon: require("@assets/explore/join.png"),
			searchWidth: 0,
			searchPlaceHolder: "复仇者联盟",
			searchVal: "",

			activeName: "home",
			moiveList: [],
			// 右侧推荐视频
			recommendList: [
				{
					title: "哈儿的移动城堡",
					rating: 9.5,
					thumb: require("@assets/thumb.png"),
					year: 2020,
					location: "日本",
					type: "动画",
					playImg: require("@assets/play_small_checked.png"),
				},
				{
					title: "奥特曼",
					rating: 9.5,
					thumb: require("@assets/thumb.png"),
					year: 2020,
					location: "日本",
					type: "动画",
					playImg: require("@assets/play_small_checked.png"),
				},
				{
					title: "乌鸦坐飞机",
					rating: 9.5,
					thumb: require("@assets/thumb.png"),
					year: 2020,
					location: "日本",
					type: "动画",
					playImg: require("@assets/play_small_checked.png"),
				},
				{
					title: "鲨鱼哥",
					rating: 9.5,
					thumb: require("@assets/thumb.png"),
					year: 2020,
					location: "日本",
					type: "动画",
					playImg: require("@assets/play_small_checked.png"),
				},
			],
			channelList: [],
			news: [],
			ongoing: [],
			matching: [],
			searchList: [],
			keyword: "",
			thumb: require("@assets/thumb.png"),
			typeObject: {
				movie: "电影",
				tv: "剧集",
			},
			loading: false,
			tabLoading: false,
			pagination: {
				currentPage: 1,
				pageSize: 0,
				numFound: 0,
				totalPage: 0,
			},

			seachImage: require("@assets/search/search.png"),
			groupImage: require("@assets/explore/group.png"),
			exploreMaintain: false,
			exploreMaintainMsg: ''
		}
	},
	methods: {
		async addPlayList(item) {
			console.log(item)
			const result = await this.api.setPlayList({
				videoId: item.videoInfo.videoId,
			})
			console.log(result)
			if (result.code === 1) {
				this.$message.success(result.msg)
				const channel = this.channelList.find((i) => i.activeName === this.activeName)
				this.clickTab(channel)
			} else this.$message.error(result.msg)
		},
		async init() {
			this.loading = true
			const result = await this.api.getExplore()
			if(result.code === 32) {
				this.exploreMaintain = true
				this.exploreMaintainMsg = result.msg
			} else {
				this.channelList = result.data.channel
				console.log(result)
				this.activeName = this.channelList[0].channelName
				this.ongoing = result.data.ongoing
				this.news = result.data.news
				this.matching = result.data.matching
			}
			this.loading = false
		},
		handleClick(tab, event) {
			console.log(tab, event)
		},
		async clickTab(item) {
			console.log(item)
			this.tabLoading = true
			this.news = []
			const tab = this.channelList[Number(item.index)]
			console.log(tab)
			const result = await this.api.getExplore({
				channelId: tab.channelId,
			})
			this.news = result.data.news
			this.tabLoading = false
			console.log(result)
		},
		pageChange(e) {
			this.pagination.currentPage = e
			this.search(this.keyword)
		},
		async search(keyword) {
			this.loading = true
			this.channelList = []
			this.searchList = []
			console.log(keyword)
			if (!keyword) {
				this.init()
				this.type = "normal"
				this.loading = false
			} else {
				const result = await this.api.getExploreSearch({
					keyword,
					currentPage: this.pagination.currentPage,
				})
				this.keyword = keyword

				if (result.data.list.length) {
					this.type = "result"
					this.searchList = result.data.list
					this.pagination = result.data.pagination
				} else {
					this.type = "resultEmpty"
				}
				this.loading = false
			}
		},
		async addWatch(item) {
			console.log(item)
			const result = await this.api.addExplore({
				externalId: item.externalId,
				status: item.status,
				...item.videoInfo,

				exploreId: 1,
			})
			if (result.code === 1) {
				this.$message.success("正在组队中，还差" + result.data.number + "人")
				item.remain = result.data.number
				this.$forceUpdate()
			} else {
				this.$message.error(result.msg)
			}
		},
	},
	mounted() {
		this.init()
	},
	computed: {
		isLogin() {
			console.log(this.$store.state.userData)
			return this.$store.state.userData && this.$store.state.userData.token
		},
		userData() {
			return this.$store.state.userData
		},
	},
	components: {
		searchBar,
		pageSelect,
	},
}
</script>

<style lang="scss">
.explore {
	background: rgb(19, 21, 48);
	width: calc(100% - 100px);
	height: 100%;
	overflow-y: auto;
	overflow-x: hidden;
	position: relative;
	padding: 0 50px;
	.title {
		margin-top: 50px;
		.title-1 {
			font-size: 24px;
			font-family: PingFang SC;
			font-weight: 500;
			line-height: 33px;
			color: #ffffff;
			letter-spacing: 1px;
		}
		.title-2 {
			margin-top: 10px;
			font-size: 30px;
			font-family: PingFang SC;
			font-weight: 600;
			line-height: 42px;
			color: #ffffff;
			letter-spacing: 2px;
		}
	}
	.type-box {
		position: relative;
		.moive-list {
			display: flex;
			justify-content: flex-start;
			flex-wrap: nowrap;
			align-items: center;
			overflow-x: auto;
			.moive-item {
				// width: 14%;
				width: 166px;
				margin-left: 1%;
				margin-right: 1%;
				margin-bottom: 40px;
				flex-shrink: 0;
				.moive-img {
					position: relative;
					border-radius: 10px;
					overflow: hidden;
					cursor: pointer;
					img {
						// position: absolute;
						top: 0;
						left: 0;
						width: 100%;
						height: 100%;
						z-index: 10;
					}
					.moive-title-box {
						position: absolute;
						left: 0;
						bottom: 0;
						z-index: 11;
						padding-left: 10px;
						width: calc(100% - 20px);
						z-index: 11;
						padding-left: 10px;
						padding-right: 10px;
						background: linear-gradient(
							180deg,
							rgba(0, 0, 0, 0) 0%,
							rgba(0, 0, 0, 0.4) 43%,
							rgba(0, 0, 0, 0.8) 100%
						);
						padding-bottom: 8px;

						.title-1 {
							font-size: 19px;
							font-family: PingFang SC;
							font-weight: 500;
							line-height: 26px;
							color: #ffffff;
						}
						.title-2 {
							font-size: 14px;
							font-family: PingFang SC;
							font-weight: 400;
							line-height: 20px;
							color: #ffffff;
							margin-top: 6px;
						}
					}
					.moive-tag {
						position: absolute;
						right: 16px;
						top: 12px;
						z-index: 11;
						height: 30px;
						background: #121330;
						opacity: 0.9;
						border-radius: 5px;
						font-size: 14px;
						font-family: PingFang SC;
						font-weight: 500;
						line-height: 20px;
						color: #ff3465;
						display: flex;
						justify-content: center;
						align-items: center;
						padding: 0 8px;
					}
				}
				.moive-button {
					width: 100%;
					height: 50px;
					background: rgb(26, 29, 54);
					border-radius: 5px;
					margin-top: 10px;
					display: flex;
					justify-content: center;
					align-items: center;
					cursor: pointer;
					img {
						width: 30px;
						height: 30px;
						margin-right: 10px;
					}
					span {
						font-size: 16px;
						font-family: PingFang SC;
						font-weight: 500;
						line-height: 22px;
						color: #797a8e;
					}
				}
			}
		}
		.moive-title {
			margin-bottom: 20px;
			margin-top: 20px;
			display: flex;
			justify-content: flex-start;
			align-items: center;
			margin-left: 2%;
			img {
				width: 18px;
				height: 18px;
			}
			span {
				font-size: 18px;
				font-family: PingFang SC;
				font-weight: 600;
				line-height: 25px;
				color: #797a8e;
				margin-left: 10px;
			}
		}
		.moive-list-history {
			display: flex;
			justify-content: flex-start;
			flex-wrap: nowrap;
			align-items: center;
			overflow-x: auto;
			.moive-item {
				// width: 14%;
				width: 166px;
				margin-left: 1%;
				margin-right: 1%;
				margin-bottom: 40px;
				flex-shrink: 0;
				.moive-img {
					position: relative;
					border-radius: 10px;
					overflow: hidden;
					cursor: pointer;
					img {
						// position: absolute;
						top: 0;
						left: 0;
						width: 100%;
						height: 100%;
						z-index: 10;
					}

					.moive-tag {
						position: absolute;
						right: 16px;
						top: 12px;
						z-index: 11;
						padding: 0 8px;
						height: 30px;
						background: #121330;
						opacity: 0.9;
						border-radius: 5px;
						font-size: 14px;
						font-family: PingFang SC;
						font-weight: 500;
						line-height: 20px;
						color: #ff3465;
						display: flex;
						justify-content: center;
						align-items: center;
					}
				}
				.moive-title-box {
					width: 100%;
					z-index: 11;
					padding-left: 10px;
					margin-top: 10px;
					.title-1 {
						font-size: 19px;
						font-family: PingFang SC;
						font-weight: 500;
						line-height: 26px;
						color: #b6b7c3;

						overflow: hidden;
						white-space: nowrap;
						text-overflow: ellipsis;
					}
					.title-1:hover {
						cursor: pointer;
						text-decoration: underline;
					}
					.title-2 {
						font-size: 14px;
						font-family: PingFang SC;
						font-weight: 400;
						line-height: 20px;
						color: #797a8e;
						margin-top: 6px;
					}
					.title-2:hover {
						cursor: pointer;
						text-decoration: underline;
					}
				}
			}
		}
		.moive-list-matching {
			display: flex;
			justify-content: flex-start;
			align-items: flex-start;
			flex-wrap: nowrap;
			overflow-x: auto;
			margin-bottom: 40px;
			.moive-item {
				width: 30%;
				margin-left: 1%;
				margin-right: 1%;
				margin-bottom: 40px;
				display: flex;
				justify-content: center;
				align-items: flex-start;
				flex-shrink: 0;
				.moive-img {
					position: relative;
					border-radius: 10px;
					overflow: hidden;
					cursor: pointer;
					width: 60%;
					flex-shrink: 0;
					img {
						// position: absolute;
						top: 0;
						left: 0;
						width: 100%;
						height: 100%;
						z-index: 10;
					}

					.moive-tag {
						position: absolute;
						right: 16px;
						top: 12px;
						z-index: 11;
						height: 30px;
						background: #121330;
						opacity: 0.9;
						border-radius: 5px;
						font-size: 14px;
						font-family: PingFang SC;
						font-weight: 500;
						line-height: 20px;
						color: #ff3465;
						display: flex;
						justify-content: center;
						align-items: center;
						padding: 0 8px;
					}
				}
				.moive-title-box {
					width: 35%;
					flex-shrink: 0;
					margin-left: 5%;
					.title-1 {
						font-size: 18px;
						font-family: PingFang SC;
						font-weight: 500;
						line-height: 26px;
						color: #b6b7c3;
						opacity: 1;
						margin-top: 40px;
						display: inline-block;
						overflow: hidden;
						text-overflow: ellipsis;
						cursor: pointer;
						display: -webkit-box;
						-webkit-line-clamp: 3;
						-webkit-box-orient: vertical;
					}
					.title-2 {
						font-size: 14px;
						font-family: PingFang SC;
						font-weight: 400;
						line-height: 20px;
						color: #797a8e;
						margin-top: 10px;
					}
					.title-3 {
						background: rgba(182, 183, 195, 0.1);
						display: flex;
						justify-content: center;
						align-items: center;
						margin-top: 40px;
						box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.1);
						padding: 14px 0;

						border-radius: 5px;
						cursor: pointer;
						img {
							width: 30px;
							height: 30px;
							margin-right: 10px;
						}
						span {
							font-size: 16px;
							font-family: PingFang SC;
							font-weight: 500;
							line-height: 22px;
							color: #ff3465;
						}
					}
				}
				.moive-button {
					width: 100%;
					height: 50px;
					background: rgb(26, 29, 54);
					border-radius: 5px;
					margin-top: 10px;
					display: flex;
					justify-content: center;
					align-items: center;
					cursor: pointer;
					img {
						width: 30px;
						height: 30px;
						margin-right: 10px;
					}
					span {
						font-size: 16px;
						font-family: PingFang SC;
						font-weight: 500;
						line-height: 22px;
						color: #797a8e;
					}
				}
			}
		}
	}
	.result-title {
		margin-top: 30px;
		.result-title-keyword {
			font-size: 22px;
			font-family: PingFang SC;
			font-weight: 600;
			line-height: 30px;
			color: #ff3465;
		}
		.result-title-number {
			font-size: 22px;
			font-family: PingFang SC;
			font-weight: 400;
			line-height: 30px;
			color: #797a8e;
			margin-left: 10px;
		}
	}
	.result-content {
		margin-top: 80px;
		display: flex;
		justify-content: center;
		align-items: center;
		flex-direction: column;
		flex-wrap: wrap;
		.result-content-title1 {
			font-size: 20px;
			font-family: PingFang SC;
			font-weight: 400;
			line-height: 28px;
			color: #999999;
			.keyword {
				font-size: 20px;
				font-family: PingFang SC;
				font-weight: 600;
				line-height: 28px;
				color: #ff3465;
			}
		}
		.result-contennt-title2 {
			font-size: 20px;
			font-family: PingFang SC;
			font-weight: 400;
			line-height: 28px;
			color: #999999;
			margin-top: 10px;
		}
		img {
			width: 300px;
			height: 210px;
		}
	}
	.result-box {
		display: flex;
		justify-content: flex-start;
		align-items: flex-start;
		flex-wrap: wrap;
		margin-top: 90px;
		.result-item {
			display: flex;
			justify-content: flex-start;
			align-items: flex-start;
			margin-right: 100px;
			margin-bottom: 40px;
			width: 420px;
			.result-item-img {
				position: relative;
				width: 166px;
				height: 240px;
				height: auto;
				overflow:hidden;
				border-radius: 10px;
				img {
					width: 100%;
					height: 100%;
				}
				.moive-tag {
					position: absolute;
					right: 8px;
					top: 8px;
					z-index: 11;

					height: 24px;
					background: #121330;
					opacity: 0.9;
					border-radius: 5px;
					font-size: 14px;
					font-family: PingFang SC;
					font-weight: 500;
					line-height: 20px;
					color: #ff3465;
					display: flex;
					justify-content: center;
					align-items: center;
					height: 30px;
					width: 50px;
				}
			}
			.result-item-text {
				display: flex;
				flex-direction: column;
				flex-wrap: wrap;
				justify-content: flex-start;
				align-items: flex-start;
				width: 55%;
				.result-item-text-box {
					margin-left: 20px;
					width: 100%;
					margin-top: 0;
					.result-item-text-box-title {
						font-size: 19px;
						font-family: PingFang SC;
						font-weight: 500;
						line-height: 26px;
						color: #b6b7c3;
						margin-top: 40px;
					}
					.result-item-text-box-title:hover {
						cursor: pointer;
						text-decoration: underline;
					}
					.date {
						font-size: 14px;
						font-family: PingFang SC;
						font-weight: 400;
						line-height: 20px;
						color: #797a8e;
						margin-top: 6px;
					}
					.date:hover {
						cursor: pointer;
						text-decoration: underline;
					}
				}
				.moive-button {
					width: 166px;
					height: 50px;
					background: rgb(26, 29, 54);
					border-radius: 5px;
					margin-top: 10px;
					display: flex;
					justify-content: center;
					align-items: center;
					cursor: pointer;
					margin-top: 60px;
					img {
						width: 30px;
						height: 30px;
						margin-right: 10px;
					}
					span {
						font-size: 16px;
						font-family: PingFang SC;
						font-weight: 500;
						line-height: 22px;
						color: #797a8e;
					}
				}
			}
		}
	}
}

@media screen and (max-width: 1300px) {
	.explore {
		.title {
			.title-1 {
			}
			.title-2 {
			}
		}
		.type-box {
			.moive-list {
				.moive-item {
					.moive-img {
						img {
						}
						.moive-title-box {
							.title-1 {
							}
							.title-2 {
							}
						}
						.moive-tag {
						}
					}
					.moive-button {
						img {
						}
						span {
						}
					}
				}
			}
			.moive-title {
				img {
				}
				span {
				}
			}
			.moive-list-history {
				.moive-item {
					.moive-img {
						img {
						}

						.moive-tag {
						}
					}
					.moive-title-box {
						.title-1 {
						}
						.title-1:hover {
						}
						.title-2 {
						}
						.title-2:hover {
						}
					}
				}
			}
		}
		.result-title {
			.result-title-keyword {
			}
			.result-title-number {
			}
		}
		.result-content {
			.result-content-title1 {
				.keyword {
				}
			}
			.result-contennt-title2 {
			}
			img {
			}
		}
		.result-box {
			.result-item {
				.result-item-img {
					img {
					}
					.moive-tag {
					}
				}
				.result-item-text {
					.result-item-text-box {
						.result-item-text-box-title {
						}
						.result-item-text-box-title:hover {
						}
						.date {
						}
						.date:hover {
						}
					}
					.moive-button {
						img {
						}
						span {
						}
					}
				}
			}
		}
	}
}

.el-tabs__nav-wrap::after {
	display: none;
}
.el-tabs__item {
	width: 80px;
	text-align: center;
	color: rgb(112, 113, 132) !important;
}
.el-tabs__active-bar {
	background-color: #ff3465 !important;
}
.el-tabs__item.is-active {
	color: #ff3465 !important;
}
.el-tabs__item:hover {
	color: #ff3465 !important;
}

@media screen and (max-width: 1680px) {
	.explore {
		.title {
			.title-1 {
				font-size: 20px;
			}
			.title-2 {
				font-size: 25px;
			}
		}
		.type-box {
			.moive-list {
				.moive-item {
					//   width: 16%;
					width: 166px;
					margin: 0 2%;
					margin-bottom: 20px;
					.moive-img {
						height: auto;
						.moive-title-box {
							.title-1 {
								font-size: 14px;
							}
							.title-2 {
								font-size: 12px;
							}
						}
					}
					.moive-button {
						width: 100%;
						height: 40px;
						img {
							width: 24px;
							height: 24px;
						}
						span {
							font-size: 14px;
						}
					}
				}
			}
			.moive-list-history {
				.moive-item {
					width: 166px;
					margin: 0 2%;
					margin-bottom: 20px;
					.moive-img {
						margin-right: 0;
						img {
							display: block;
						}
					}
					.moive-title-box {
						padding-left: 0;
						.title-1 {
							width: 126px;
							font-size: 14px;
						}
					}
					.moive-button {
						width: 126px;
						height: 40px;
						img {
							width: 24px;
							height: 24px;
						}
						span {
							font-size: 14px;
						}
					}
				}
			}
			.moive-list-matching {
				display: flex;
				justify-content: flex-start;
				align-items: flex-start;
				flex-wrap: nowrap;
				overflow-x: auto;
				.moive-item {
					width: 23%;
					margin-left: 1%;
					margin-right: 1%;
					margin-bottom: 40px;
					display: flex;
					justify-content: center;
					align-items: flex-start;
					flex-shrink: 0;
					.moive-img {
						position: relative;
						border-radius: 10px;
						overflow: hidden;
						cursor: pointer;
						width: 60%;
						flex-shrink: 0;
						img {
							// position: absolute;
							top: 0;
							left: 0;
							width: 100%;
							height: 100%;
							z-index: 10;
						}

						.moive-tag {
							position: absolute;
							right: 16px;
							top: 12px;
							z-index: 11;
							height: 30px;
							background: #121330;
							opacity: 0.9;
							border-radius: 5px;
							font-size: 14px;
							font-family: PingFang SC;
							font-weight: 500;
							line-height: 20px;
							color: #ff3465;
							display: flex;
							justify-content: center;
							align-items: center;
							padding: 0 8px;
						}
					}
					.moive-title-box {
						width: 35%;
						flex-shrink: 0;
						margin-left: 5%;
						.title-1 {
							font-size: 16px;
							font-family: PingFang SC;
							font-weight: 500;
							line-height: 26px;
							color: #b6b7c3;
							opacity: 1;
							margin-top: 10px;
							display: inline-block;
							overflow: hidden;
							text-overflow: ellipsis;
							cursor: pointer;
							display: -webkit-box;
							-webkit-line-clamp: 3;
							-webkit-box-orient: vertical;
						}
						.title-2 {
							font-size: 12px;
							font-family: PingFang SC;
							font-weight: 400;
							line-height: 20px;
							color: #797a8e;
							margin-top: 10px;
						}
						.title-3 {
							background: rgba(182, 183, 195, 0.1);
							display: flex;
							justify-content: center;
							align-items: center;
							margin-top: 30px;
							box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.1);
							padding: 8px 0;

							border-radius: 5px;
							img {
								width: 20px;
								height: 20px;
								margin-right: 10px;
							}
							span {
								font-size: 13px;
								font-family: PingFang SC;
								font-weight: 500;
								line-height: 22px;
								color: #ff3465;
							}
						}
					}
				}
			}
		}
	}
}
.type-box {
	.el-tabs__nav-wrap {
		width: 70%;
	}
	.type-search-box {
		position: absolute;
		right: 2%;
		width: 25%;
		z-index: 20;
		.el-input--prefix .el-input__inner {
			padding-left: 40px;
		}
		input {
			border: 1px solid rgba(255, 255, 255, 0.1);
			background: rgba(255, 255, 255, 0.1);
			height: 40px;
		}
	}
}


.moive-list::-webkit-scrollbar{
  width: 5px;
  height: 10px;
}
.moive-list::-webkit-scrollbar-thumb {
	border-radius: 1em;
	background-color: rgba(50, 50, 50, 0.3);
}
.moive-list::-webkit-scrollbar-track {
	border-radius: 1em;
	background-color: rgba(50, 50, 50, 0.1);
}

.moive-list-history::-webkit-scrollbar{
  width: 5px;
  height: 10px;
}
.moive-list-history::-webkit-scrollbar-thumb {
	border-radius: 1em;
	background-color: rgba(50, 50, 50, 0.3);
}
.moive-list-history::-webkit-scrollbar-track {
	border-radius: 1em;
	background-color: rgba(50, 50, 50, 0.1);
}



.moive-list-matching::-webkit-scrollbar{
  width: 5px;
  height: 10px;
}
.moive-list-matching::-webkit-scrollbar-thumb {
	border-radius: 1em;
	background-color: rgba(50, 50, 50, 0.3);
}
.moive-list-matching::-webkit-scrollbar-track {
	border-radius: 1em;
	background-color: rgba(50, 50, 50, 0.1);
}
</style>
